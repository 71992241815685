<template>
  <div class="showrooms">
    <div class="title">
      <div class="title-img">
        <img src="~assets/images/icon/start.png" alt="" />
      </div>
      <section>SHOWROOMS</section>
    </div>
    <div class="block">
      <div class="b-title">{{ title }}</div>
      <div class="tate">
        <div class="data-item" v-for="item in date">
          <p>{{ item.time1 }}</p>
          <p>and</p>
          <p>{{ item.time2 }}</p>
          <section>{{ item.address }}</section>
        </div>
      </div>
      <div class="b-title">SHOWROOM LOCATIONS</div>
      <div class="list d-block d-lg-flex col-lg-11">
        <div class="item col-lg-6 col-xl-6 col-sm-12" v-for="obj in list">
          <div class="item-block">
            <div class="item-title">{{ obj.title }}</div>
            <!-- <div class="item-content" v-html="obj.description"></div> -->
            <section v-html="obj.description"></section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAllTimes, getAllActivity } from "network/showRooms";
import { getSiteinfoByName } from "network/type";
export default {
  data() {
    return {
      date: [],
      list: [],
      title: "",
    };
  },
  created() {
    getAllTimes().then((res) => {
      if (res.data.code == 1) {
        this.date = res.data.data;
      }
    });
    getAllActivity().then((res) => {
      if (res.data.code == 1) {
        this.list = res.data.data;
      }
    });
    getSiteinfoByName("showrooms_tips").then((res) => {
      if (res.data.code == 1) {
        this.title = res.data.data;
      }
    });
  },
  methods: {},
};
</script>
<style scoped>
p {
  margin: 0 !important;
}
.showrooms {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.title {
  background: #ff7f00;
  display: flex;
  font-size: 0.75rem;
  color: #fff;
  align-items: center;
  padding: 0.25rem 0.5rem;
}
.title-img {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}
.title-img img {
  width: 0.5rem;
  height: 0.5rem;
}
.b-title {
  text-align: center;
  font-size: 0.55rem;
  color: #ff7f00;
  margin-top: 1rem;
}
.data-item {
  margin-top: 1rem;
  color: #333333;
  font-size: 0.35rem;
  text-align: center;
}
.data-item p {
  font-weight: 600;
}
.data-item section {
  color: #737373;
}
.list {
  margin: 0.5rem auto;
  font-size: 0.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}
.item {
  padding: 0.25rem;
}
.item-block {
  border: 1px solid #333333;
  height: 100%;
  padding: 0.5rem;
}
.item-title {
  font-size: 0.5rem;
  color: #ff7f00;
}
.item-content {
  margin-top: 0.5rem;
}
.item-content p {
  background-color: red;
}
section p {
  margin: 0 !important;
}
</style>
