import { request } from "./request";

export function getAllTimes() {
    return request({
        url: '/api/showrooms/getAllTimes'
    })
};

export function getAllActivity() {
    return request({
        url: '/api/showrooms/getAllActivity'
    })
};